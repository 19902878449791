import React, { useState} from 'react';

const Faqaccordian = ({faqAccordianData})=>{

    const [openIndex, setOpenIndex] = useState(null);

    const toggleAnswer = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
   
    return(
        <div>
               <section className='faq-section '>
                <div className='container'>
                    <div className='faq-contents'>
                        <div className='text-center '>
                            <p className='fw-700 fs-38 plus font-23' style={{color:'rgba(34, 34, 34, 1)'}}>Frequently Asked Questions</p>
                            <p className='fw-400 fs-16 plus' style={{color:'rgba(79, 79, 79, 1)'}}>We provide answers to common questions about our products/services.</p>
                        </div>
                        <div id='faq-container'>
                            {faqAccordianData.map((qa, index) => (
                                <div key={index} className='faq-qa' onClick={() => toggleAnswer(index)}>
                                    <div className='question-wrapper d-flex justify-content-between'>
                                        <p className='fw-500 fs-18 plus ' style={{color:'rgba(34, 34, 34, 1)'}}>{qa.que1}</p>
                                        <button className='toggle-btn1' >{openIndex === index ? '▲' : '▼'}</button>
                                    </div>
                                    <div className='fs-16 fw-400 plus ' style={{ display: openIndex === index ? 'block' : 'none',color:'rgba(79, 79, 79, 1)' }}>{qa.ans1}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Faqaccordian;