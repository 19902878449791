// import React, { useState, useEffect } from 'react';
import Header from "../Components/Header";
import Footer from "../Components/footer";
// import Bigcontact from "../Components/Bigcontact";
import Form from "../Components/Form";
// import Minicontact from "../Components/Minicontact";
// import Faq from "../Components/Faq";
// import WebdevCard from "../Components/WebdevCard";
// import Testimonial1 from "../Components/Testimonial1";
import Chatbox from "../Components/Chatbox";
import SEOPage from "../Components/SEOPage";
// import Blog1 from "../Components/Blog1";
import { Helmet } from "react-helmet";

import Faqaccordian from '../Components/Faqaccordian';

const custompageimg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/custompageimg.svg";



function Webdev() {



  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   fetch('https://blog.datametricks.com/wp-json/wp/v2/categories')
  //     .then((response) => response.json())
  //     .then((data) => setCategories(data.slice(0,3)))
  //     .catch((error) => console.error('Error fetching categories:', error));
  // }, []);

 
  // const categoryId=3;


  // const newwebdev1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newwebdev1.svg";


  const serviceSchemaData1 = 
  [
  {
    "@context": "https://schema.org",
    "@type": "Service",
    "serviceType": "Website Design and Development",
    "provider": {
      "@type": "Organization",
      "name": "Datametricks Consulting Pvt Ltd",
      "url": "https://www.datametricks.com/",
      "logo": "https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg",
      "sameAs": [
        "https://www.facebook.com/people/DataMetricks-Consulting-PvtLtd/61554350043470/",
        "https://www.instagram.com/datametricks_pvt_ltd/",
        "https://x.com/datametricks",
        "https://www.linkedin.com/company/datametricks-consulting-pvt-ltd/"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 7303946657",
        "contactType": "Customer Service",
        "email": "contact@datametricks.com"
      },
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "A40, ITHUM TOWER, Industrial Area, Sector 62",
        "addressLocality": "Noida",
        "addressRegion": "Uttar Pradesh",
        "postalCode": "201309",
        "addressCountry": "IN"
      }
    },
    "areaServed": {
      "@type": "Place",
      "name": "India"
    },
    "description": "Datametricks provides professional website design and development services, delivering custom web solutions for businesses of all sizes. Our services include responsive design, e-commerce development, and CMS integration to help you establish a strong online presence.",
    "offers": {
      "@type": "Offer",
      "url": "https://www.datametricks.com/service/website-design-development",
      "priceCurrency": "INR",
      "eligibleRegion": {
        "@type": "Place",
        "name": "India"
      },
      "availability": "https://schema.org/InStock",
      "validThrough": "2024-12-31"
    }
  }
];


// const backgroundimg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec1bg.png";
// const sec4img1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec4img1.png";
const sec5dot1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png";
const sec5dot2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png";
const sec5dot3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png";
const sec5dot4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png";
const stagesbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png";
const newwebdevimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-1.svg";
const newwebdevimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-2.svg";
const newwebdevimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-3.svg";
const newwebdevimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-4.svg";
const newwebdevimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-5.svg";
const newwebdevimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-6.svg";
const newwebdevimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-7.svg";
const newwebdevimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img-8.svg";
const newwebdevimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img9.svg";
const newwebdevimg10 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/new-web-dev-img10.svg";
// const testimonialimage = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/testimonial-img.png";
// const webnewimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg1.png";
// const webnewimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg2.png";
// const webnewimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg3.png";
// const webnewimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg4.png";
// const webnewimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg5.png";
// const webnewimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg6.png";
// const webnewimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg7.png";
// const webnewimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg8.png";
// const webnewimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/webnewimg9.png";

  var faqData=[
            {que1:"1. What is web development and why is it important?",ans1:"Web development involves creating and maintaining websites. It includes web design, web publishing, web programming, and database management. A well-developed website enhances a business's online presence, boosts credibility, attracts customers, and drives sales." },
            {que1:"2. How much does it cost to develop a website? ", ans1:"The cost of developing a website can vary greatly depending on factors such as complexity, features, design, and the development company."},
            {que1:"3. How long does it take to build a website? ",ans1:"The time required to build a website depends on its complexity and the specific requirements of the project. A simple website might take a few weeks, while a more complex site with custom features can take several months."},
            {que1:"4. What are the key features of a good website? ",ans1:"A good website should be user-friendly, responsive (works on all devices), fast-loading, secure, SEO-optimized, and visually appealing. It should also have clear navigation, engaging content, and effective call-to-actions. "},
            {que1:"5. Do I need a mobile-friendly website? ",ans1:"Yes, having a mobile-friendly (responsive) website is essential as more people access the internet through mobile devices. A mobile-friendly site improves user experience, boosts SEO rankings, and increases the chances of retaining visitors. "},
            {que1:"6. What is SEO and why is it important for my website? ",ans1:"SEO (Search Engine Optimization) involves optimizing your website to rank higher in search engine results. It is important because higher rankings lead to increased visibility, more traffic, and potentially more customers. "},
            {que1:"7. Can I update my website content by myself? ",ans1:"Yes, if your website is built with a Content Management System (CMS) like WordPress, you can easily update your content without needing technical knowledge. A CMS allows you to add, edit, and delete content as needed. " },
            {que1:"8. What is the difference between web design and web development? ",ans1:"Web design focuses on the visual aspects of a website, including layout, color schemes, and graphics. Web development, on the other hand, involves coding and programming to create the website’s functionality and features. " },
            {que1:"9. Why should I choose DataMetricks for my web development needs? ",ans1:"Datametricks Consulting offers expert web development services tailored to your business needs. We prioritize user experience, use the latest technologies, and provide ongoing support to ensure your website is always optimized and effective. "},
            {que1:"10. What kind of support do you provide after the website is launched? ",ans1:"We offer comprehensive support and maintenance services, including regular updates, security checks, performance optimization, and troubleshooting to ensure your website remains secure and performs at its best. "},    
      ];

  return (
    <div>
        <SEOPage 
                title="Professional Website Design & Development Services in Noida | Custom Web Solutions - DataMetricks"
                description="Datametricks offers expert website design and development services, delivering custom,
                              responsive, and user-friendly web solutions tailored to your business needs. Enhance your online
                                presence with our web design expertise. Contact us today for a consultation!"
                canonicalUrl="https://www.datametricks.com/service/website-design-development-solutions"  />

        <Helmet>
             <script type="application/ld+json">
                  {JSON.stringify(serviceSchemaData1)}
             </script>
      </Helmet>
     

      <Header />



    
      <section>
        <div className='mt-35' style={{marginTop:'80px'}}>
          <div className='newwebdevbg'>
            <p className='fs-50 fw-800 plus text-center font-23' style={{color:'rgba(255, 255, 255, 1)'}}>Empower Your Business with the Best Website Development Company in Noida </p>
            <p className='fs-20 fw-400 plus text-center mb-0 font-18 d-none d-lg-block d-md-block d-sm-none' style={{color:'rgba(255, 255, 255, 0.6)'}}>We are the leading web-development company in noida, we are committed to delivering<br/> customized solutions that meet your business needs.</p>
            <p className='fs-20 fw-400 plus text-center mb-0 font-18 d-block d-sm-block d-md-none d-lg-none' style={{color:'rgba(255, 255, 255, 0.6)'}}>We are the leading web-development company in noida, we are committed to delivering customized solutions that meet your business needs.</p>
            <div style={{textAlign:'center',marginTop:'25px'}}>
            <button type="button" class="btn px-5  pb-2 font-18 getstartedbtn " style={{backgroundColor:'rgba(0, 110, 205, 1)',borderRadius:'50px',color:'white',fontSize:'19px',fontWeight:'500',border:'none',boxShadow:'none',  overflow: 'hidden', position: 'relative',  transition: 'background-color 0.3s ease'}}>
                        <span className="getstarted-text">
                                 Get Started
                        </span>
            </button>
            </div>
          </div>
      </div>
      </section> 
     
      <section>
      <div className='p-lg-5 p-20 ' style={{backgroundColor:'rgba(232, 245, 255, 1)'}}>
        <div className='text-center'>
          <p className='fw-700 fs-35 plus font-23'>We Build Websites That Grow Your Business </p>
          <p className='fs-18 fw-400 plus font-18 pl0 pr0' style={{color:'rgba(0, 0, 0, 0.6)',paddingLeft:'190px',paddingRight:'190px'}}>We don't just build websites, we create online solutions that drive results. Our deep understanding of diverse business landscapes allows us to craft websites that perfectly align with your unique goals. We combine user-friendly design, cutting-edge technology, and a focus on conversions to deliver a website that elevates your online presence and fuels your success. </p>
          </div>
      </div>

      </section>

      <section className='mt-lg-4 mt-20'>
        <div>
          <p className='fs-35 fw-700 plus text-center d-none d-lg-block d-md-block d-sm-none font-22'>Maximize Your Online Success with Our All-Inclusive<br/>Web Development Services </p>
          <p className='fs-35 fw-700 plus text-center d-block d-sm-block d-md-none d-lg-none font-22'>Maximize Your Online Success with Our All-Inclusive Web Development Services </p>
        </div>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='d-none d-lg-block d-md-block d-sm-none'>
             <div className='d-flex gap-5 mt-4 '>
                <div >
                  <img src={newwebdevimg1} alt='' className='img-fluid' />
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>Website Design & Development</p>
                </div>
                <div >
                  <img src={newwebdevimg2} alt='' className='pt-1 img-fluid' />
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>E-Commerce Development</p>
                </div>
                <div >
                  <img src={newwebdevimg3} alt='' className='pt-1 img-fluid'/>
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>Content Management System</p>
                </div>
                <div >
                  <img src={newwebdevimg4} alt='' className='pt-1 img-fluid' />
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>Website Maintenance & Support</p>
                </div>
                <div  >
                  <img src={newwebdevimg5} alt='' className='pt-1 img-fluid'/>
                  <p className='fw-500 fs-17 plus pt-3 font-15' style={{color:'rgba(19, 19, 19, 1)'}}>Responsive Design </p>
                </div>
                </div>
                </div>

                <div className='d-block d-lg-none d-md-none d-sm-block'>
             <div className='row '>
            
                <div className='col-sm-2 col-6 text-center' >
                  <img src={newwebdevimg1} alt='' className='img-fluid' />
                  <p className='fw-500 fs-17 plus  font-15 pt-3' style={{color:'rgba(19, 19, 19, 1)'}}>Website Design & Development</p>
                </div>
                <div className='col-sm-2 col-6 text-center'>
                  <img src={newwebdevimg2} alt='' className=' img-fluid pt-2' />
                  <p className='fw-500 fs-17 plus  font-15 pt-3' style={{color:'rgba(19, 19, 19, 1)'}}>Website Design & Development</p>
                </div>
                <div className='col-sm-2 col-6 text-center' >
                  <img src={newwebdevimg3} alt='' className=' img-fluid'/>
                  <p className='fw-500 fs-17 plus font-15 pt-3' style={{color:'rgba(19, 19, 19, 1)'}}>Content Management System</p>
                </div>
                <div className='col-sm-2 col-6 text-center'>
                  <img src={newwebdevimg4} alt='' className=' img-fluid' />
                  <p className='fw-500 fs-17 plus  font-15 pt-3' style={{color:'rgba(19, 19, 19, 1)'}}>Website Maintenance & Support</p>
                </div>
                <div  className='col-sm-2 col-12  text-center textcenter'>
                  <img src={newwebdevimg5} alt='' className=' img-fluid pt-1'/>
                  <p className='fw-500 fs-17 plus  font-15 pt-3' style={{color:'rgba(19, 19, 19, 1)'}}>Responsive Design </p>
                </div>
                
                </div>
                </div>
            </div>
          </div>

        </div>
      </section>

      <section className='mt-4 container'>
        <p className='fw-700 fs-35 plus text-center font-23' style={{color:'rgba(0, 0, 0, 1)'}}> Your Vision, Our Expertise </p>
      <div className='mt-4 mobilewrap' style={{display:'flex',justifyContent:'center'}}>
                       
 
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg6} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>Custom Website</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Tailored Digital Solutions to Meet Your Unique Business Needs to Growth.</p>
                        <a href='/service/custom-website-design-development-solutions' ><button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg7} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>Mobile App Development</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Building Intuitive and High-Performance Mobile App for All Platforms</p>
                               <a href='/service/mobile-app-design-development-solutions' > <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg8} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>E Commerce Website</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Empowering Your Online Store with Seamless Ecommerce Development</p>
                               <a href='/service/ecommerce-website-design-development-solutions' >    <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg9} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>CMS Website</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Efficient Content Management Solutions for Dynamic & Scalable Websites</p>
                               <a href='/service/cms-website-design-development-solutions' >    <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                       
                       <div className="hover-container">
                           <img className='imageFluid ' src={newwebdevimg10} alt='Icon2'  />
                           <div className='col-3-boxforcareer'>
                               <p className="plus fs-15 fw-600 ms-2 pt-1" style={{color:'rgba(51, 51, 51, 1)'}}>SEO</p>
                               <p className="plus fs-14 ms-2" style={{color:'rgba(53, 53, 53, 0.72)', textAlign:'left'}}>Boost Your Search Rankings and Drive Organic Traffic for Your Business.</p>
                               <a href='/service/search-engine-optimization-seo' >     <button style={{color:'#151515',border:'none'}} type="button" className="btn plus fs-14">
                                   <span className="learn-more">Read more</span>
                                   <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                               </button></a>
                           </div>
                       </div>

                     
                     
                          
                   </div>
      </section>

      <section className="mt-lg-4 mt-10 ">
               <div>
                    <p className="fs-35 fw-700 plus text-center mb-0 font-23"> Cutting-Edge Technologies for </p>
                    <p className="fs-35 fw-700 plus text-center font-23">Modern Web Solutions</p>

               </div>
               <div className="container mt-lg-5 mt-30">
                    <img src={custompageimg} alt="" className="img-fluid" />
               </div>
              
            </section>

      <section>

<div className="mt-4">
        <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Web Development</p>
        <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>

 </div>
 <div className="container mt-lg-5 ">
<div className="row ms-lg-5">
  <div className="col-lg-4 ">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt=""  />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">
          1. Discovery and Planning
        </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
         Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot2} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">2. Development </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Transforming designs into functional <br /> websites with
          clean code and seamless functionality.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">3. Launch</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Deploying the website to the live <br /> environment and
          making it accessible to your audience.
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div className="container">

<div className="col-lg-12 ms-lg-5">
<img className="img-fluid" src={stagesbg} alt="" />
</div>
</div>
<div className="container pt-4 ">
<div className="row ms-lg-5">
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">4. Design</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Bringing your vision to life with captivating visuals and
          intuitive user interfaces.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot4} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">5. Testing </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Thoroughly checking every aspect of the website to ensure
          it meets quality standards and performs flawlessly.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">6. Beyond</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Providing ongoing support, maintenance, and updates to
          ensure your website remains optimized and up to date.
        </p>
      </div>
    </div>
  </div>
</div>



</div>
    
</section>


    

      {/* section 2 */}
      {/* <section>
        <div className="sec2 pt-lg-5 pt-20 pb-4 ">
          <p className="sec2heading text-center mb-0 Seopara1 font-23">
       
            We Build Websites That Grow Your Business
          </p>
        
          <div className="container mt-lg-5 mt-20">
              <div className="row justify-content-center">
                <div className="col-lg-4 textcenter">
                  <img src={newwebdev1} alt="" className="img-fluid " />
                </div>
                <div className="col-lg-5 ">
                  <p className="fs-18 plus textcenter font-18 mt-lg-5 mt-30 mb0" style={{color:'rgba(0, 0, 0, 0.6)'}}>We don't just build websites; we create online solutions that drive results. Our deep understanding of diverse business landscapes allows us to craft websites that perfectly align with your unique goals. We combine user-friendly design, cutting-edge technology, and a focus on conversions to deliver a website that elevates your online presence and fuels your success. </p>
                </div>
              </div>
          </div>
        </div>
      </section> */}
      {/* section 2 end */}

      {/* section 3 */}
      {/* <section>
        <div className="pt-lg-4">
          <p className="sec2heading text-center  font-23">
            
         
            Maximize Your Online Success with Our All - Inclusive Web Development Services
          </p>
        </div>
        <div className="sec3bg img-fluid pt-5 mt-lg-5 mt-20 ">
        

  

    <div className="container ">
  <div className="row card-design-responsive">
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex  align-items-center justify-content-center ">
        <div className="section-text text-center px-2">
          <h1 className="font-family tc fs-22 fw-600">Website Design & Development</h1>
          <p className="para ">Crafting bespoke websites that merge creativity  with functionality to elevate your online presence.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex align-items-center justify-content-center">
        <div className="section-text text-center">
          <h1 className="font-family tc fs-22 fw-600">E-commerce Development</h1>
          <p className="para">Building robust online stores that drive sales and  enhance customer experiences.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex align-items-center justify-content-center">
        <div className="section-text text-center">
          <h1 className="font-family tc fs-22 fw-600">Content Management Systems</h1>
          <p className="para">Empowering you with intuitive platforms to  manage and update your website content effortlessly.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-2 d-none d-lg-block"></div>
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex align-items-center justify-content-center">
        <div className="section-text text-center ">
          <h1 className="font-family tc fs-22 fw-600">Website Maintenance & Support</h1>
          <p className="para">Ensuring your website remains secure, updated,  and optimized for peak performance.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-12 pos-relative ">
      <div className="sec3div1 d-flex align-items-center justify-content-center">
        <div className="section-text text-center">
          <h1 className="font-family tc fs-22 fw-600">Responsive Design</h1>
          <p className="para">Creating seamless user experiences across all devices,  ensuring your website looks and functions flawlessly on every screen size.</p>
        </div>
      </div>
    </div>
    <div className="col-lg-2 d-none d-lg-block"></div>
  </div>
</div>    
        </div>
      </section> */}
   

      {/* <section>
              <div className="mt-lg-5 mt-10">
                  <p className="sec2heading text-center mb-0 Seopara1 font-23">  Your Vision, Our Expertise  </p>
                 
              </div>

              <div className="container mt-lg-5 mt-10">
                <div className="row">
                    <div className="col-lg-4">
                            <img src={webnewimg1} alt="" className="img-fluid "/>
                    </div>
                    <div className="col-lg-3 d-none d-sm-none d-lg-block d-md-block ">
                          <img src={webnewimg2} alt="" className="img-fluid "/>
                          <img src={webnewimg3} alt="" className="img-fluid pt-4 ps-1 pb-4"/>
                    </div>
                    <div className="col-lg-5 ">
                        <p className="font-18 pt-20 textcenter mt0" style={{fontSize:'18px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif" ,marginTop:'80px'}}> we understand that a professional website is more than just a digital presence; it's a powerful tool that drives business growth and success. Here’s how our expert web development services can transform your business </p>
                    </div>
                </div>

              </div>

            
      </section> */}

      {/* <section>
                <div className='container mt-lg-5 '>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={webnewimg4} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className='mt-lg-5 textcenter mt-10 '>
                            <h1 className="font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Enhanced Credibility and Trust</h1>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> A professionally designed website establishes your brand's credibility, making a strong first impression on potential customers. Our designs are not only visually appealing but also trustworthy, instilling confidence in your audience.  </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5 mt-10'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6  d-none d-sm-none d-lg-block d-md-block'> 
                        <div className=' mt-lg-5 textcenter '>
                            <h1 className="font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Increased Visibility and Reach</h1>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> With our SEO-optimized websites, your business will rank higher on search engines, increasing visibility and attracting a wider audience. This expanded reach can lead to more traffic, greater brand awareness, and higher conversion rates.  </p>
                        </div>
                        </div>

                        <div className='col-lg-4  d-none d-sm-none d-lg-block d-md-block'> 
                            <img src={webnewimg5} alt='' className='img-fluid  '/>
                        </div>
                      
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={webnewimg6} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className='mt-lg-5 mt-10 textcenter'>
                            <h1 className="font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Optimized User Experience</h1>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> Our user-centric design approach ensures that your website is easy to navigate, with intuitive layouts and fast loading times. A seamless user experience keeps visitors engaged and encourages them to explore more of what you offer.   </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-5  d-none d-sm-none d-lg-block d-md-block'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' mt-5 '>
                            <h1 style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Responsive Design for All Devices</h1>
                            <p style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We create websites that look and function flawlessly on all devices, from desktops to smartphones. This ensures that your audience can access your site anytime, anywhere, enhancing convenience and user satisfaction.    </p>
                        </div>
                        </div>

                        <div className='col-lg-4'> 
                            <img src={webnewimg7} alt='' className='img-fluid  '/>
                        </div>
                      
                      
                    </div>
                </div>

                <div className='container mt-lg-5 '>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={webnewimg8} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' mt-lg-5 mt-10 textcenter'>
                            <h1 className="font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Custom Solutions Tailored to Your Needs</h1>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We provide personalized web development solutions that cater to your specific business goals and requirements. Whether you need an e-commerce platform, a content management system, or a corporate site, we've got you covered. </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' mt-lg-5  d-none d-sm-none d-lg-block d-md-block '>
                            <h1 style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Ongoing Support and Maintenance</h1>
                            <p style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> Your commitment to your success doesn’t end with the launch. We offer ongoing support and maintenance to ensure your website remains secure, up-to-date, and optimized for peak performance.     </p>
                        </div>
                        </div>

                        <div className='col-lg-4'> 
                            <img src={webnewimg9} alt='' className='img-fluid '/>
                        </div>
                      
                      
                    </div>
                </div>
            </section> */}

   
   
      {/* <section>
        <div className="sec4">
          <div className="mt-4">
         
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <img style={{ maxWidth: "100%" }} src={sec4img1} alt=""  className="img-fluid"/>
                </div>
                <div className="col-lg-7 col-md-6 col-sm-6 col-12">
                  <p className="para1 font-18 textcenter mb0">
                  We create a professional website that not only represents your brand effectively but also drives measurable business results. Let us help you unlock your business's full potential online.
                  </p>
                </div>
              </div>

            
            </div>
          </div>

       
          <div className="pt-4">
            <p className="sec2heading text-center mb-0 textcenter font-23">
              
              Our Streamlined Web Development Process from Concept to Launch
            </p>
         
          </div>
          <div className="container mt-5 ">
            <div className="row ms-lg-5">
              <div className="col-lg-4 ">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot1} alt=""  />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">
                      1.Discovery and Planning
                    </p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                     Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot2} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">2.Development </p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Transforming designs into functional <br /> websites with
                      clean code and seamless functionality.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot1} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">3.Launch</p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Deploying the website to the live <br /> environment and
                      making it accessible to your audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
          
            <div className="col-lg-12 ms-lg-5">
              <img className="img-fluid" src={stagesbg} alt="" />
            </div>
          </div>
          <div className="container pt-4 ">
            <div className="row ms-lg-5">
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot3} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">4.design</p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Bringing your vision to life with captivating visuals and
                      intuitive user interfaces.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot4} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">5.Testing </p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Thoroughly checking every aspect of the website to ensure
                      it meets quality standards and performs flawlessly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot3} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">6.Beyond</p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Providing ongoing support, maintenance, and updates to
                      ensure your website remains optimized and up to date.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-lg-5 pt-10">
              <p className="sec2heading text-center mb-0 Seopara1 font-23">
              
                Cutting-Edge Technologies for  Modern Web Solutions
              </p>
            
            </div>

            <div>
              <WebdevCard />
            </div>

          </div>
       
          <div className="container ">
            <div className="mx-lg-5 my-4 ">
              <Minicontact />
            </div>
          </div>

        

              <div>
                <Testimonial1/>
              </div>

        

 <section className='mt-lg-4 mt-20'>
<div>
      <p className='text-center fw-600 fs-35 plus font-23'>Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span></p>
  </div>
  <div className='mt-4'>
  <Blog1 categoryId={categoryId}/>
  </div>
</section> 



       
          <div className="mt-lg-5 mt-30">
          <Faq faqData={faqData}/>
          </div>
        </div>
      </section> */}

<section>
        <div className='newhome-bg1 resnewhome-bg1'>  
  <div className='container p-20'>
    <div className='row justify-content-center'>
      <div className='col-lg-7 col-md-6 col-12 textcenter '>
          <h2 className='fs-50 fw-700 plus text-white font-25 mt-35' style={{marginTop:'200px'}}>Transform Your Digital Presence</h2>
          <p className='fs-18 fw-400 plus font-17' style={{color:'rgba(192, 199, 206, 1)'}}>Ready to transform your digital presence? <br/> Fill out the form below to get a free consultation or a  quote for our top-notch website development and digital marketing services. Let's take your  business to new heights together!</p>
      </div>
      <div className='col-lg-4 col-md-6 col-12 '>
         <div>
          <Form/>
         </div>
        </div>
    </div>
  </div>
  </div>
  
</section>

      <section>
      <div className="mt-lg-5 ">
          {/* <Faq faqData={faqData}/> */}
          <Faqaccordian faqAccordianData={faqData}/>
          </div>
      </section>

     

      <section>
  <div>
    <Chatbox/>
  </div>
</section>

<section>
<div className='p-3 mt-2' style={{backgroundColor:'rgba(210, 234, 255, 1)'}}>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <div className='row'>
              <div className='col-md-3'>
                <p className='fw-700 fs-30 plus font-23 textcenter' style={{color:'rgba(0, 79, 146, 1)'}}>Explore More Services</p>

              </div>
              <div className='col-md-9'>
                <div className='d-none d-sm-none d-md-block d-lg-block'>
                <div className='d-flex  pt-3 ' >
                  <div>
                  <a href='/service/social-media-marketing' style={{textDecoration:'none'}}>  <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Social Media Marketing &nbsp; </p></a>
                  </div>
                  <div>
                      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/services/performance-marketing' style={{textDecoration:'none'}}>          <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}> Performance Marketing &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/service/search-engine-optimization-seo' style={{textDecoration:'none'}}>          <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>SEO &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-16 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/services/digital-marketing' style={{textDecoration:'none'}}>          <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Digital Marketing &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-16 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/services/graphic-desinging' style={{textDecoration:'none'}}>            <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Graphic Designing</p></a>
                  </div>
                </div>
                </div>


                <div className='d-none d-sm-none d-md-block d-lg-block'>
                <div className='d-flex gap-1'>
                  <div>
                  <a href='/service/custom-website-design-development-solutions' style={{textDecoration:'none'}}>            <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Custom Development&nbsp; </p></a>
                  </div>
                  <div>
                      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/service/ecommerce-website-design-development-solutions' style={{textDecoration:'none'}}>              <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}> E Commerce Website  &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/service/cms-website-design-development-solutions' style={{textDecoration:'none'}}>              <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>CMS Website &nbsp;</p></a>
                  </div>
                  <div>
                      <p className='fs-16 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>| &nbsp;</p>
                  </div>
                  <div>
                  <a href='/service/mobile-app-design-development-solutions' style={{textDecoration:'none'}}>            <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Mobile App Development &nbsp;</p></a>
                  </div>
                
                 
                </div>
                </div>

                <div className='d-block d-sm-block d-md-none d-lg-none'>
                  <div className='row'>
                    <div className='col-6'>
                    <div>
                    <a href='/service/social-media-marketing' style={{textDecoration:'none'}}>         <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Social media Marketing </p></a>
                    </div>

                    </div>
                    <div className='col-6'>
                    <div>
                    <a href='/services/performance-marketing' style={{textDecoration:'none'}}>      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}> Performance Marketing  </p></a>
                    </div>

                    </div>

                  
                    <div className='col-6'>
                    <div>
                    <a href='/services/digital-marketing' style={{textDecoration:'none'}}>       <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Digital Marketing</p></a>
                    </div>

                    </div>

                    <div className='col-6'>
                    <div>
                    <a href='/services/graphic-desinging' style={{textDecoration:'none'}}>       <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Graphic Designing</p></a>
                    </div>

                    </div>
                    <div className='col-6'>
                    <div>
                    <a href='/service/custom-website-design-development-solutions' style={{textDecoration:'none'}}>      <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Custom Development</p></a>
                    </div>

                    </div>

                    <div className='col-6'>
                    <div>
                    <a href='/service/ecommerce-website-design-development-solutions' style={{textDecoration:'none'}}>    <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>E Commerce Website </p></a>
                    </div>

                    </div>
                    <div className='col-6'>
                    <div>
                    <a href='/service/cms-website-design-development-solutions' style={{textDecoration:'none'}}>    <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>CMS Website</p></a>
                    </div>

                    </div>
                    <div className='col-6'>
                    <div>
              <a href='/service/mobile-app-design-development-solutions' style={{textDecoration:'none'}}> <p className='fs-15 plus fw-400' style={{color:'rgba(34, 34, 34, 1)'}}>Mobile App Development</p></a>
                    </div>

                    </div>

                  </div>
                </div>
                
                </div>
            </div>
          </div>
        </div>

</div>
</section>


     
    <div>
      <Footer />
      </div>
     
    </div>
  );
}
export default Webdev;
