import Accordion from 'react-bootstrap/Accordion';

const Faq = ({faqData})=>{
   
    return(
        <div>
               <section className=' pt0 ' >
                        <div className="container">
                            <div class="row"  >
                                <div class="text-center pb-4">
                                    <h4 class="font-family fw-700 fc-black fs-40  font-24">Frequently Asked Questions</h4>
                                    <p className='fs-16 fw-400 plus pt-2' style={{color:'rgba(79, 79, 79, 1)'}}>We provide answers to common questions about our products/services.</p>
                                </div>
                                <div >
                                <Accordion defaultActiveKey="0" style={{position:'relative',zIndex:'0'}}>
                                        
                                    {faqData?.map((item, index) => (
                                      <Accordion.Item eventKey={index}>

                                       <Accordion.Header>{item.que}</Accordion.Header>
                                       <Accordion.Body style={{marginLeft:'20px'}} className='ml0' >{item.ans}</Accordion.Body>
                                     
                                       </Accordion.Item>
                                          ))}

                                </Accordion>
                                </div>

                            </div>
                        </div>

                    </section>
        </div>
    )
}
export default Faq;