import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEOPage = ({ title, description, canonicalUrl }) => {
    return (
        <div>
            <Helmet>
            <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
          
        </div>
    );
};

export default SEOPage;
