import React, { useRef } from "react";

const Testimonial1 = ()=>{
const googlereview = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/googlereview.svg";
 const ratingstar = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ratingstar.svg";
const viewongoogle = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/viewongoogle.svg";
  

    const carouselRef = useRef(null);

  // Function to handle right scroll
  const handleNextClick = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const cardWidth = carousel.children[0].offsetWidth + 30; // Including margin
      const scrollLeft = carousel.scrollLeft + cardWidth;
      carousel.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

  // Function to handle left scroll
  const handlePrevClick = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const cardWidth = carousel.children[0].offsetWidth + 30; // Including margin
      const scrollLeft = carousel.scrollLeft - cardWidth;
      carousel.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

    return(

      

        <div>

<section>
        <div className='container mt-20 '>
          <p className='fs-35 fw-600 lexend text-center font-30 mt-10'>The <span className='fs-35 fw-600 font-30 ' style={{color:'rgba(0, 83, 154, 1)'}}>Trust</span> We've <span className='fs-35 fw-600 font-30' style={{color:'rgba(0, 83, 154, 1)'}}>Earned </span> </p>
          <div className='row justify-content-center mt-lg-4 p-2'>
            <div className='col-lg-12' style={{border:'1px solid rgba(197, 197, 197, 1)',borderRadius:'19px'}}>
                <div className='row'>
                  <div className='col-md-4  '>
                      <img src={googlereview} alt='' className='img-fluid mt0' style={{marginTop:'62px'}} />
                  </div>
                  <div className='col-md-8  '>

                  <div className="review-container">
      <button className="arrow left-arrow" onClick={handlePrevClick} style={{position:'relative',zIndex:'0'}}>
        &#8249;
      </button>
      <div className="review-carousel" ref={carouselRef}>
        <div className="review-card" style={{marginLeft:'50px'}}>
          <div className="review-header">
            <div className="avatar fs-20 fw-500 plus" style={{color:'rgba(255, 255, 255, 1)'}}>S</div>
            <h3 className="fs-16 fw-500 plus pt-1" style={{color:'rgba(48, 48, 48, 1)'}}>Sanjeet</h3>
          </div>
          <div className="review-rating">
            <img src={ratingstar} alt=""  />
          </div>
          <div className="review-text  fs-15 plus " style={{color:'rgba(0, 0, 0, 0.45)'}}>
          my name is Sanjeet. I am running my own company in the name of Narayan Home Facility Service Private Limited in Sector 57, Gurgaon. For which I am getting my mobile application website design and Facebook ad promotion done by DataMetricks consulting Pvt Ltd Company, which is currently working and I have been associated with them for the last 4 years. They have supported me every time and have done the best work. And I hope that you people also join them, give your work to them, you will get the best result and you will get growth for your business, so thank you Mr. Sandeep ji. And I have lots of best wishes and hope from my side to the entire team of DataMetricks consulting Pvt Ltd May you all move ahead in life and keep doing good to others. Do good and good will happen!
          </div>
          {/* <a href=""> <span className="fs-15 plus">Read more</span></a> */}
          <div className="pt-3">
          <a href="https://www.google.com/search?q=datametricks&rlz=1C5CHFA_enIN1098IN1098&oq=datametricks+&gs_lcrp=EgZjaHJvbWUqDAgAEEUYOxiABBiiBDIMCAAQRRg7GIAEGKIEMggIARBFGCcYOzIGCAIQRRg7MgYIAxBFGDsyFQgEEC4YFBivARjHARiHAhiABBiOBTIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPNIBCDI3MDRqMGo3qAIIsAIB&sourceid=chrome&ie=UTF-8#lrd=0x390ce550ac43f535:0x7efd3596bc8722ed,1,,,," className="google-link">
            <img src={viewongoogle} alt="" />
          </a>
          </div>
        </div>

        {/* 2nd card  */}

        <div className="review-card" >
          <div className="review-header">
            <div className="avatar fs-20 fw-500 plus" style={{color:'rgba(255, 255, 255, 1)'}}>V</div>
            <h3 className="fs-16 fw-500 plus pt-1" style={{color:'rgba(48, 48, 48, 1)'}}>Vrindavan Kunj Gali</h3>
          </div>
          <div className="review-rating ">
            <img src={ratingstar} alt=""  />
          </div>
          <div className="review-text1  fs-15 plus " style={{color:'rgba(0, 0, 0, 0.45)'}}>
          I have great experience with DataMetricks Consulting Private Limited, I am constantly impressed this Company. Thank you for your hard work!
          </div>
          <div className="pt-3">
          <a href="https://www.google.com/search?q=datametricks&rlz=1C5CHFA_enIN1098IN1098&oq=datametricks+&gs_lcrp=EgZjaHJvbWUqDAgAEEUYOxiABBiiBDIMCAAQRRg7GIAEGKIEMggIARBFGCcYOzIGCAIQRRg7MgYIAxBFGDsyFQgEEC4YFBivARjHARiHAhiABBiOBTIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPNIBCDI3MDRqMGo3qAIIsAIB&sourceid=chrome&ie=UTF-8#lrd=0x390ce550ac43f535:0x7efd3596bc8722ed,1,,,," className="google-link">
            <img src={viewongoogle} alt="" />
          </a>
          </div>
        </div>

           {/* 3rd card  */}

           <div className="review-card" >
          <div className="review-header">
            <div className="avatar fs-20 fw-500 plus" style={{color:'rgba(255, 255, 255, 1)'}}>H</div>
            <h3 className="fs-16 fw-500 plus pt-1" style={{color:'rgba(48, 48, 48, 1)'}}>HealthoMax Drqlick</h3>
          </div>
          <div className="review-rating">
            <img src={ratingstar} alt=""  />
          </div>
          <div className="review-text2  fs-15 plus " style={{color:'rgba(0, 0, 0, 0.45)'}}>
          One of Noida finest digital marketing companies, boasting a team of experts who excel in elevating your online presence. Highly recommended!
          </div>
          <div className="pt-3">
          <a href="https://www.google.com/search?q=datametricks&rlz=1C5CHFA_enIN1098IN1098&oq=datametricks+&gs_lcrp=EgZjaHJvbWUqDAgAEEUYOxiABBiiBDIMCAAQRRg7GIAEGKIEMggIARBFGCcYOzIGCAIQRRg7MgYIAxBFGDsyFQgEEC4YFBivARjHARiHAhiABBiOBTIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPNIBCDI3MDRqMGo3qAIIsAIB&sourceid=chrome&ie=UTF-8#lrd=0x390ce550ac43f535:0x7efd3596bc8722ed,1,,,," className="google-link">
            <img src={viewongoogle} alt="" />
          </a>
          </div>
        </div>

        {/* 4th card  */}

        <div className="review-card" >
          <div className="review-header">
            <div className="avatar fs-20 fw-500 plus" style={{color:'rgba(255, 255, 255, 1)'}}>R</div>
            <h3 className="fs-16 fw-500 plus pt-1" style={{color:'rgba(48, 48, 48, 1)'}}>Rachana singh</h3>
          </div>
          <div className="review-rating">
            <img src={ratingstar} alt=""  />
          </div>
          <div className="review-text3  fs-15 plus " style={{color:'rgba(0, 0, 0, 0.45)'}}>
          This digital marketing company in Noida sets the standard with its skilled team and cutting-edge strategies, truly exceptional.
          </div>
          <div className="pt-3">
          <a href="https://www.google.com/search?q=datametricks&rlz=1C5CHFA_enIN1098IN1098&oq=datametricks+&gs_lcrp=EgZjaHJvbWUqDAgAEEUYOxiABBiiBDIMCAAQRRg7GIAEGKIEMggIARBFGCcYOzIGCAIQRRg7MgYIAxBFGDsyFQgEEC4YFBivARjHARiHAhiABBiOBTIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPNIBCDI3MDRqMGo3qAIIsAIB&sourceid=chrome&ie=UTF-8#lrd=0x390ce550ac43f535:0x7efd3596bc8722ed,1,,,," className="google-link">
            <img src={viewongoogle} alt="" />
          </a>
          </div>
        </div>

           {/* 5th card  */}
      </div>
      <button className="arrow right-arrow" onClick={handleNextClick} style={{position:'relative',zIndex:'0'}}>
        &#8250;
      </button>
    </div>
                  </div>
                </div>
            </div>
  
          </div>
        </div>
</section>

        </div>
       
    )
}
export default Testimonial1;