import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const Bigcontact = ()=>{
    // ================ Mailer code start from here =====================
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const [responseMessage, setResponseMessage] = useState(""); 
  const [isChecked, setIsChecked] = useState(false); // State for checkbox

  const [loading, setLoading] = useState(false); // State for loader
  const navigate = useNavigate(); // Initialize useNavigate
  const [errors, setErrors] = useState({}); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
 
  const validateForm = () => {
    let formErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/; // Assumes phone number should be 10 digits

    if (!formData.email || !emailRegex.test(formData.email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      formErrors.phone = "Please enter a valid 10-digit phone number.";
    }

    if (!formData.message || formData.message.trim().length < 10) {
        formErrors.message = "Please enter a message with at least 10 characters.";
      }
      if (!isChecked) {
        formErrors.checkbox = "You must agree to the Terms and Conditions.";
      }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setLoading(true); // Show loader

    try {
      const response = await fetch(
        "https://mailer.datametricks.com/send-email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json(); // Parse JSON from the response

      setResponseMessage(result.message);

      if (result.status === "success") {
        setTimeout(() => {
          setLoading(false); // Hide loader
          navigate("/Successemailpage"); // Redirect to Thank You page
        }, 1000); // Delay redirect by 2 seconds
      } else {
        setLoading(false); // Hide loader if not successful
      }
    } catch (error) {
      setResponseMessage("Error submitting form.");
      setLoading(false); // Hide loader on error
    }
  };

  // ================ Mailer code end here =====================
    return(
        <div className="pos-relative">
             
             <div className='formdiv p-5'>
                                </div>
                                <form onSubmit={handleSubmit}>
                                <div className='p-3 pos-relative' >
                <p className='ff text-center ' style={{ fontSize: '30px', fontWeight: 500, color: 'white' }}>Get In Touch</p>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label className='formlabel' htmlFor="">* Name</label> <br />
                                                <input className='info' type="text"  id="fname"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}  required placeholder='Enter name' /> <br />
                                            </div>
                                            <div className="col-lg-6">

                                                <label className='formlabel pt-10' htmlFor="">* Email</label> <br />
                                                <input className='info' type="email" placeholder='Enter email'  id="email" name="email"   value={formData.email}
                              onChange={handleChange} required /> <br />

                                            </div>
                                            {errors.email && <p className="error-text mb-0" style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>{errors.email}</p>}

                                        </div>

                                        <label className='formlabel pt-2' htmlFor="">* Mobile Number</label> <br />
                                        <input className='info' type="tel" placeholder="Enter number" name="phone"
                            value={formData.phone}
                            onChange={handleChange} required  />
                                            {errors.phone && <p className="error-text mb-0" style={{ fontSize: "12px", color: "red", marginTop: "10px" }}>{errors.phone}</p>}

                                        <label className='formlabel pt-2' htmlFor="">Message</label> <br />
                                        <textarea className='settextarea'   name="message"  value={formData.message}
                          onChange={handleChange}
                          form="usrform" required  style={{ resize: 'none' }}   cols="20" rows="5" placeholder='Type your message'></textarea> <br />
                           {errors.message && (
              <p className="error-text mb-0" style={{ fontSize: "12px", color: "red", marginTop: "5px" }} > {errors.message} </p>  )}
                                        <div className='d-flex gap-2 pt-1'>
                                            <div>
                                                 <input className='setcheckbox' type="checkbox"   value=""
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}/>

                                            </div>

                                            <div>
                                                  <label className='formlabel ' for="vehicle1">I accept the <span><a href="Termscondition" style={{color:'white'}}> Terms & Conditions</a></span> </label> <br />
                                            </div>
                                        </div>
                                        {errors.checkbox && <p className="error-text mb-0" style={{ fontSize: "12px", color: "red", }}>{errors.checkbox}</p>}

                                      
                                        <div className=''>
                                            <button className='btn-contact px-5 mt-1  ' style={{fontWeight:'600', fontSize:'16px'}} disabled={loading}>
                                            {loading ? (
                            <>
                        
                              <div className="spinner"></div>
                             
                            
                            </>
                          ) : (
                            "Submit"
                          )}
                                                </button>
                                        </div>


                                    </div>
                </div>
                </form>
        </div>
    )
}
export default Bigcontact ;
