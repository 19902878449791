import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Autoplay} from 'swiper/modules';
import Button from 'react-bootstrap/Button';
// import { FaArrowRight } from 'react-icons/fa';

import 'swiper/css';
import 'swiper/css/pagination';
const Scroller = () => {
  return (
        <div>
            
                   {/* Slide background section  */}
        <section>
        <div style={{position:'relative'}}>
      <Swiper
        direction={'vertical'}
        pagination={{
        
          clickable: true,
        }}
        loop={true}
        speed={1500}
      
        autoplay={{
          delay: 2000,
          stopOnLastSlide:false,

          waitForTransition:2000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper" style={{position:'relative'}} >
        <SwiperSlide>
            <div className="slide-1">
           
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="slide-2">
           
            </div>
            </SwiperSlide>
        <SwiperSlide>
            <div className="slide-3">

            </div></SwiperSlide>
        <SwiperSlide>
            <div className="slide-4">
                
            </div></SwiperSlide>

      </Swiper>
      <a href='/our-completed-projects/monovouge'>     <div className="container pt-lg-2 pt8" style={{position:'absolute',top:'0%',left:'0%',right:'0%',bottom:'0%',zIndex:999}}>
            <div className="slideOne text-center text-white">
                <p className=" mollio  fs-14 " style={{marginBottom:'0'}}>Create Your Dream Home</p>
                <p className="mollio  fs-14 mb-0 pt5"s> Find Inspiration in Every Corner</p>
                <p className="pop  fs-11 pt-lg-2">Transform your home into a space that reflects your style, personality, and comfort with Monovouge. We believe that every home tells a story, with a curated selection of beautiful Website.</p>
            </div>
            <div className="text-center " >
           
         <Button  className="btn-1 px-5 text-white mt-lg-2 mt7" style={{borderRadius:'17px',backgroundColor:'rgba(79, 166, 140, 1)',border:'none'}} >Explore Collections </Button>
            </div>
          
      </div></a>
     
    </div>
      </section>

        </div>
  );
}

export default Scroller;
