import Header from "../Components/Header";
import Minicontact from "../Components/Minicontact";
import Footer from "../Components/footer";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Bigcontact from '../Components/Bigcontact';
import Chatbox from "../Components/Chatbox";
import SEOPage from "../Components/SEOPage";


const Graphicdesigning = () => {
    const serviceSchemaData6 = {
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Graphic Designing",
        "provider": {
          "@type": "Organization",
          "name": "Datametricks Consulting Pvt Ltd",
          "url": "https://www.datametricks.com/",
          "logo": "https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 7303946657",
            "contactType": "Customer Service",
            "email": "contact@datametricks.com"
          }
        },
        "description": "Datametricks offers professional graphic design services, creating logos, marketing materials, and website visuals to help businesses enhance their branding.",
        "areaServed": {
          "@type": "Place",
          "name": "India"
        }
      };
    

const Grapimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-1.png";
const Grapimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-2.png";
const Grapimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-3.png";
const Grapimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-4.png";
const Grapimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-5.png";
const Grapimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-6.png";
const Grapimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-7.png";
const Grapimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-8.png";
const Grapimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-9.png";
const Grapimg10 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-10.png";
const Grapimg11 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-11.png";
const Grapimg12 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-12.png";
const Grapimg13 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-13.png";
const Grapimg14 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Grap-bg-14.png";
const sec5dot1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png";
const sec5dot2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png";
const stagesbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png";
const sec5dot3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png";
const sec5dot4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png";

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
      <div>
         <SEOPage 
                title="Creative Graphic Design Services | Datametricks"
                description="Enhance your brand with Datametricks' professional graphic design services. We create
stunning visuals for logos, marketing materials, websites, and more, ensuring your brand
stands out."
                canonicalUrl="https://www.datametricks.com/services/graphic-desinging"/>
        <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(serviceSchemaData6) }} />
        {/* section 1 */}
        <section>
          <Header />
        </section>
        

          {/* section 2  */}
           {/* <section className="graphsec-3" style={{marginTop:'7%',marginBottom:'2%'}}>
                    <div className="container px-5" >

                            <div className="row">
                                <div className="col-lg-12 col-sm-6 col-md-6 col-12">
                                    <div style={{position:'relative'}}>
                                         <img src={Grapimg1} alt="" className="img-fluid  " />
                                         <h1 className="ah1" style={{fontfamily: "Plus Jakarta Sans", sans-serif, fontWeight:"bold", fontSize:42, position:'absolute',top:'47%',left:'1%'}}>  Transforming Your Brand's Narrative    </h1>
                                         <h1 className="ah1" style={{fontfamily: "Plus Jakarta Sans", sans-serif, fontWeight:"bold", fontSize:42, color:'#00539A',position:'absolute',top:'57%',left:'1%'}}>Through Creative Design</h1>
                                         <p className="ap" style={{fontfamily: "Plus Jakarta Sans", sans-serif, fontWeight:"regular", fontSize:16,position:'absolute',top:'70%',left:'1%',paddingRight:'45%'}}>Briefly explain how you create successful mobile apps (e.g.,  "Custom mobile apps designed to solve your business challenges and drive  results").</p>
                                         <button className=" abutton git-button font-family fw-500 fs-18" style={{bottom:'8%',right:'88%', position:'absolute'}}> Get in Touch </button> 
                                         <div className="d-flex gap-3 " style={{position:'absolute',left:'86%',right:'0%',bottom:'3%'}}>
                                                 <img src={Grapimg2} alt="" className="img-fluid  " />
                                                 <img src={Grapimg3} alt="" className="img-fluid  " />
                                                 <img src={Grapimg4} alt="" className="img-fluid  " />
                                         </div>
                                    </div>
                                </div>
                            </div>
                    </div>
         </section>  */}

<section className="" style={{ marginTop: '7%', marginBottom: '2%' }}>
    <div className="container px-5 graphsec-3">
        <div className="row">
            <div className="col-12">
                <div style={{ position: 'relative' }}>
                    <img src={Grapimg1} alt="" className="img-fluid " />
                    <h1 className="ah1" style={{
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                        fontWeight: "bold",
                        fontSize: 38,
                        position: 'absolute',
                        top: '52%',
                        left: '1%',
                        transform: 'translateY(-50%)',
                        width: '100%',
                        textAlign: 'left'
                    }}>Transforming Your Brand's Narrative</h1>
                    <h1 className="ah2 " style={{
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                        fontWeight: "bold",
                        fontSize: 38,
                        color: '#00539A',
                        position: 'absolute',
                        top: '62%',
                        left: '1%',
                        transform: 'translateY(-50%)',
                        width: '100%',
                        textAlign: 'left'
                    }}>Through Creative Design</h1>
                    <p className="ap d-none d-sm-none d-md-block d-lg-block" style={{
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                        fontWeight: "regular",
                        fontSize: 16,
                        position: 'absolute',
                        top: '75%',
                        left: '1%',
                        paddingRight: '45%',
                        transform: 'translateY(-50%)',
                        width: '100%',
                        textAlign: 'left'
                    }}>Briefly explain how you create successful mobile apps (e.g., "Custom mobile apps designed to solve your business challenges and drive results").</p>
                    <button className="abutton git-button font-family fw-500 fs-18" onClick={handleShow}  style={{
                        bottom: '0%',
                        right: '86%',
                        position: 'absolute',
                        transform: 'translateY(-50%)'
                    }}>Get in Touch</button>
                      <Modal show={show} onHide={handleClose} className='modal1'   >
                          <Modal.Header closeButton className='modelheader1' ></Modal.Header>
                            <Modal.Body className='modelbody1'>  
                            <Bigcontact/>
                       </Modal.Body>
       
                          </Modal>
                    <div className="d-flex  imgdlfex  d-none d-sm-none d-md-block d-lg-block" style={{
                        position: 'absolute',
                        left: '86%',
                        right: '0%',
                        bottom: '3%',
                        transform: 'translateY(-50%)'
                    }}>
                        <a href="https://x.com/datametricks?t=tTJi9XotG-3d1NMQpxXhTQ&s=09"><img src={Grapimg2} alt="" className="img-fluid " /></a>
                        <a href="https://www.instagram.com/datametricks_pvt_ltd/"><img src={Grapimg3} alt="" className="img-fluid" /></a>
                        <a href="https://www.facebook.com/people/DataMetricks-Consulting-Pvt-Ltd/61554350043470/"><img src={Grapimg4} alt="" className="img-fluid" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



         {/* Section-3  */}

         <div className=" g-sec-3 container  ">
                <p className="paragraph6 "> Captivate Your Audience With Our    </p>
                <p className="paragraph6">Compelling Graphic Designs </p>       
        </div>
            <section>
                <div className="container mt-lg-5 mt-10  p-5" style={{ backgroundColor:'#A6DFE4' ,borderRadius:'19px' }}> 
                        <div className="row text-center">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                 <img src={Grapimg5} alt="" className=" img-fluid "  />

                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 col-12 ">
                                    <p className=" plus fs-18 plus mt-4" > Graphic designing helps to show attractive design phase sets the trajectory for the project and serves as a cornerstone for guiding  subsequent ideation. Here, our team diligently engages with our clients to fully comprehend  their needs and objectives.</p>
                                    <img src={Grapimg6} alt="" className=" img-fluid d-none s-sm-none d-sm-block d-md-block pt-lg-3"  />

                            </div>
                        </div>
                </div>
            </section>


      {/* Section-4  */}

         <div className="  container mt-lg-5 mt-10 ">
                <p className="paragraph6"> Comprehensive Graphic Design Solutions   </p>
                <p className="paragraph6">Tailored to Your Needs  </p>       
        </div>
        <section>
            <div className="container mt-lg-3 " style={{paddingLeft:'12%',paddingRight:'10%',paddingBottom:'1%', }}>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div  style={{position:'relative'}}>
                          <img src={Grapimg7} alt="" className="img-fluid graph-img-1 mx-5 my-5 "  />
                          <p className="grap-p1" style={{fontfamily:  "Plus Jakarta Sans, sans-serif", fontWeight:"semibold", fontSize:25,color:'#1E8891',position:'absolute',top:'37%',right:'-6%'}}> Logo Design</p>

                          <img src={Grapimg8} alt="" className="img-fluid graph-img-2 d-none s-sm-none d-sm-block d-md-block " style={{position:'absolute',top:'54%',right:'0%',left:'15%'}}/>
                          </div>

                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div  style={{position:'relative'}}>
                          <img src={Grapimg9} alt="" className="img-fluid mx-5 my-5 graph-img-3 " />
                          <p className="grap-p2" style={{fontfamily:  "Plus Jakarta Sans, sans-serif", fontWeight:"semibold", fontSize:25,color:'#BD2772',position:'absolute',top:'37%',right:'-13%'}}> Brand Identity</p>

                          <img src={Grapimg10} alt="" className="img-fluid graph-img-4 d-none s-sm-none d-sm-block d-md-block " style={{position:'absolute',top:'55%',right:'0%',left:'18%'}}/>
                          </div>

                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div  style={{position:'relative'}}>
                          <img src={Grapimg11} alt="" className="img-fluid mx-5 my-5 graph-img-5" />
                          <p className="grap-p3" style={{fontfamily:  "Plus Jakarta Sans, sans-serif", fontWeight:"semibold", fontSize:25,color:'#D74917',position:'absolute',top:'37%',right:'-8%'}}> Print Design</p>

                          <img src={Grapimg12} alt="" className="img-fluid graph-img-6  d-none s-sm-none d-sm-block d-md-block" style={{position:'absolute',top:'54%',right:'0%',left:'15%'}}/>
                          </div>

                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div  style={{position:'relative'}}>
                          <img src={Grapimg13} alt="" className="img-fluid mx-5 my-5  graph-img-7" />
                          <p className="grap-p4" style={{fontfamily:  "Plus Jakarta Sans, sans-serif", fontWeight:"semibold", fontSize:25,color:'#D4A800',position:'absolute',top:'37%',right:'-15%'}}> Packing Design</p>

                          <img src={Grapimg14} alt="" className="img-fluid graph-img-8 d-none s-sm-none d-sm-block d-md-block" style={{position:'absolute',top:'58%',right:'0%',left:'22%'}}/>
                          </div>

                    </div>    
                </div>

            </div>
        </section>


         {/* Section-5  */}

         <div className="  container mt-lg-5 mt-10 ">
                <p className="paragraph6"> A Collaborative Journey from    </p>
                <p className="paragraph6">Concept to Reality  </p>       
        </div>
        <div className="container mt-5 ">
            <div className="row ms-lg-5">
              <div className="col-lg-4 ">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot1} alt=""  />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">
                      1. Discovery and Planning
                    </p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                     Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot2} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">2. Development </p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Transforming designs into functional <br /> websites with
                      clean code and seamless functionality.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot1} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">3. Launch</p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Deploying the websites to the live <br /> environment and
                      making it accessible to your audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
          
          <div className="col-lg-12 ms-lg-5">
            <img className="img-fluid" src={stagesbg} alt="" />
          </div>
        </div>
        <div className="container ">
        <div className="row ms-lg-5">
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot3} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">4. Design</p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Bringing your vision to life with captivating visuals and
                      intuitive user interfaces.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot4} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">5. Testing </p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Thoroughly checking every aspect of the website to ensure
                      it meets quality standards and performs flawlessly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex  gap-2">
                  <div>
                    <img src={sec5dot3} alt="" />
                  </div>
                  <div>
                    <p className="sec5title mb-0 mt-1">6. Beyond</p>
                    <p
                      style={{ paddingLeft: "17px !important" }}
                      className="para1"
                    >
                      Providing ongoing support, maintenance, and updates to
                      ensure your website remains optimized and up to date.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>

        {/* <div className="container mt-lg-5 mt-10  " style={{paddingLeft:'15%',paddingRight:'9%'}}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot1} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 px-1'>1.Grasping Goals</p>
                                        <p className='para1 px-2 pt-2'>This phase sets the trajectory for the project and serves as a cornerstone for guiding  subsequent ideation. Here, our team diligently engages with our clients to fully comprehend  their needs and objectives.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot2} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 px-1'>2.Development </p>
                                        <p  className='para1 px-2 pt-2'>Transforming designs into functional websites with clean code and  seamless functionality.</p>
                                    </div>
                                </div>

                            </div>
                           
                        </div>
                    </div> */}

                    {/* <div className="container" style={{paddingLeft:'15%',paddingRight:'9%'}}>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <img className='img-fluid' src={stagesbg} alt="" />
                        </div>
                    </div>

                    <div className="container mt-5 " style={{paddingLeft:'15%',paddingRight:'9%'}}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot1} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 px-1'>3.Design</p>
                                        <p className='para1 px-2 pt-2'>Bringing your vision to life with captivating visuals and intuitive user interfaces.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot2} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 px-1'>4.Testing </p>
                                        <p  className='para1 px-2 pt-2'>Thoroughly checking every aspect of the website to ensure it meets quality  standards and performs flawlessly.</p>
                                    </div>
                                </div>

                            </div>
                           
                        </div>
                    </div> */}


        <section >  

        <div className="mt-5 ps-lg-5 pb-4  container">
             <Minicontact />
        </div>
      </section>

      <section>
  <div>
    <Chatbox/>
  </div>
</section>

      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Graphicdesigning;