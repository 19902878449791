import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Form = ()=>{

    // ================ Mailer code start from here =====================
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const [responseMessage, setResponseMessage] = useState(""); 
  const [loading, setLoading] = useState(false); // State for loader
  const navigate = useNavigate(); // Initialize useNavigate
  const [errors, setErrors] = useState({}); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 
  const validateForm = () => {
    let formErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/; // Assumes phone number should be 10 digits

    if (!formData.email || !emailRegex.test(formData.email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      formErrors.phone = "Please enter a valid 10-digit phone number.";
    }

    if (!formData.message || formData.message.trim().length < 10) {
        formErrors.message = "Please enter a message with at least 10 characters.";
      }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setLoading(true); // Show loader

    try {
      const response = await fetch(
        "https://mailer.datametricks.com/send-email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json(); // Parse JSON from the response

      setResponseMessage(result.message);

      if (result.status === "success") {
        setTimeout(() => {
          setLoading(false); // Hide loader
          navigate("/Successemailpage"); // Redirect to Thank You page
        }, 1000); // Delay redirect by 2 seconds
      } else {
        setLoading(false); // Hide loader if not successful
      }
    } catch (error) {
      setResponseMessage("Error submitting form.");
      setLoading(false); // Hide loader on error
    }
  };

  // ================ Mailer code end here =====================


    return(

        <div>
             <form onSubmit={handleSubmit}>

        <div className='container p-4 mt-10' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'10px',marginTop:'120px'}}>
              <p className='fs-22 fw-400 plus mb-4 text-center'>Book a Free Demo!</p>
              <div class="custom-input-container ">
                <input type="text"  
                              id="fname"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}  required />
                <label for="customFullName">* Full Name </label>
            </div>
            <div class="custom-input-container mt-4 ">
                <input type="tel"  
                             name="phone"
                            value={formData.phone}
                            onChange={handleChange} required />
                <label for="mobilenumber">* Mobile Number </label>
                {errors.phone && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "10px" }}>{errors.phone}</p>}

            </div>
            <div class="custom-input-container mt-4 ">
                <input type="email"
                 id="email" name="email"   value={formData.email}
                              onChange={handleChange} required />
                <label for="email">* Email</label>
                {errors.email && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>{errors.email}</p>}

            </div>

            <div class="custom-input-container mt-4 ">
                <input type="text" 
                name="message"  value={formData.message}
                          onChange={handleChange}
                          form="usrform" required  />
                <label for="servicename">Tell us your requirement</label>
                {errors.message && (
              <p
                className="error-text"
                style={{ fontSize: "12px", color: "red", marginTop: "5px" }}
              >
                {errors.message}
              </p>
            )}
            </div>
            <div className='mt-4 '>
            <button  type="submit" class="btn  w-100 submit-button  plus text-white  mb-2 py-2" style={{backgroundColor:' rgba(0, 83, 154, 1)',boxShadow:'none',border:'none',outline:'none',fontSize:'20px',fontWeight:'600'}} disabled={loading}>
            {loading ? (
                            <>
                        
                              <div className="spinner"></div> {/* Spinner */}
                              {/* <span className="">Sending...</span> */}
                            
                            </>
                          ) : (
                            "Submit"
                          )}
                
                </button>
            </div>
          </div>
          </form>

        </div>
       
    )
}
export default Form;