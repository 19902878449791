import Chatbox from "../Components/Chatbox";
import Header from "../Components/Header";
import Footer from '../Components/footer';
import SEOPage from "../Components/SEOPage";

const Aboutus = () =>  {
    
const dmimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/dm-img1.svg";
const dmimg2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/dm-img2.svg';
const dmimg3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/dm-img3.svg';
const dmimg4 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/dm-img4.svg';
const dmimg5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/dm-img5.svg';
const viveksir = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/viveksir.svg';
const sksir = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/sksir.svg';
const vaibhavsir = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/vaibhavsir.svg';
const dmimg6 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/dm-img6.svg';
const dmimg7 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/dm-img7.svg';
const dmimg8 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/dm-img8.svg';
const dmimg9 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/dm-img9.svg';
const callicon = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/callicon-1.svg';
const emailicon = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/emailicon-1.svg';
const locationicon = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/locationicon.svg';
const linkedicon = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/linkedinicon.svg';
    
return(
    <div>
          <SEOPage 
                title="About Datametricks Consulting Pvt Ltd | Our Story, Vision & Mission"
                description="Learn more about Datametricks, our mission, values, and the journey that has shaped us
into a leading digital solutions provider. Discover what sets us apart in the world of website
design and digital marketing."
                canonicalUrl="https://www.datametricks.com/about-us"
            />
            
        <div>
            <Header/>
        </div>


        <section className="abtsec-1" style={{marginTop:'9%'}}>
    <div className="container mb-5 " style={{position:'relative'}}>
        <div className="row ">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                <img src={dmimg1} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 ">
                <div className="row  ">
                    <div className="col-5 mb-3 pe-0 ms-4 ">
                        <img src={dmimg2} alt="" className="img-fluid" />
                    </div>
                    <div className="col-5 mb-3 ps-0 ms-4">
                        <img src={dmimg3} alt="" className="img-fluid" />
                    </div>
                    <div className="col-5 pe-0 ms-4">
                        <img src={dmimg4} alt="" className="img-fluid pt-2" />
                    </div>
                    <div className="col-5 ps-0 ms-4">
                        <img src={dmimg5} alt="" className="img-fluid pt-2" />
                    </div>
                </div>
            </div>
        </div>
        <div className="p-4 w-75 blurdiv " style={{position:'absolute',top:'85%',left:'12%',backgroundColor:'rgba(0, 0, 0, 0.62)',border:'0.5px solid rgba(255, 255, 255, 1)',borderRadius:'15px',backdropFilter:'blur(8px)'}}>
                <p className="fw-800 fs-45 plus text-white text-center font-23">About Us</p>
                <p className="fs-18 plus text-center font-18" style={{color:'rgba(255, 255, 255, 0.7)'}}>We are a team of innovative thinkers, creative designers, and tech-savvy developers committed to deliver exceptional digital solutions. Our diverse expertise allows us to tackle challenges head-on and provide customized strategies that drive results. </p>

        </div>
    </div>
</section>

{/*      
<section className="leadersec" style={{marginTop:'14%'}}>
    <div className="pb-4" style={{ backgroundColor: '#151366' }}>
        <div className="container">
            <p className="text-center text-white fw-700 fs-35 plus pt-3 font-23">Our Leadership</p>
            <div className="mt-4 p-3" style={{ backgroundColor: 'white', borderRadius: '17px' }}>
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-3 mb-md-0 text-center text-md-start">
                        <img src={viveksir} alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-8 col-sm-12">
                        <p className="pt-2 fw-400 fs-17 plus pe-lg-5 font-18">Vivek Singh, a visionary leader and seasoned Android Developer, leads Datametricks with a commitment to excellence and innovation. With a master’s degree in computer science from Delhi University, Vivek combines his technical expertise with a strategic mindset to drive the company's growth. His deep understanding of mobile app development and digital marketing ensures that Datametricks stays at the forefront of the industry.</p>
                        <p className="fw-600 fs-19 plus mb-0 pt-3 ">Vivek Singh</p>
                        <p className="fw-400 fs-16 plus" style={{ color: '#6F6F6F' }}>Director</p>
                        <button type="button" className="btn btn-sm mt-2" style={{ backgroundColor: '#015095' }}>
                        <a href="https://www.linkedin.com/in/vaibhavtripathi/" style={{textDecoration:'none'}}>    <div className="d-flex gap-3 align-items-center">
                                <img src={linkedicon} alt="" />
                                <p className="plus fs-16 text-white mb-0">Follow on Linkedin</p>
                            </div></a>
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-4 p-3" style={{ backgroundColor: 'white', borderRadius: '17px' }}>
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-3 mb-md-0 text-center text-md-start">
                        <img src={sksir} alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-8 col-sm-12">
                        <p className="pt-3 fw-400 fs-17 plus pe-lg-5 font-18">With over 12 years of experience at IBM and a proven track record in large IT programs, Surendra Kumar brings all-round functional and technical expertise to Datametricks. He holds an MBA from the University of Madras and successfully runs multiple businesses, including consulting services. Surendra's extensive knowledge and experience make him an invaluable asset to our team, guiding us through complex technical challenges and ensuring our solutions are robust and effective.</p>
                        <p className="fw-600 fs-19 plus mb-0 pt-3">Surendra Kumar</p>
                        <p className="fw-400 fs-16 plus" style={{ color: '#6F6F6F' }}>Founding Member</p>
                        <button type="button" className="btn btn-sm mt-2" style={{ backgroundColor: '#015095' }}>
                        <a href="https://www.linkedin.com/in/surendra8?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" style={{textDecoration:'none'}}>   <div className="d-flex gap-3 align-items-center">
                                <img src={linkedicon} alt="" />
                                <p className="plus fs-16 text-white mb-0">Follow on Linkedin</p>
                            </div></a>
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-4 p-3" style={{ backgroundColor: 'white', borderRadius: '17px' }}>
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-3 mb-md-0 text-center text-md-start">
                        <img src={vaibhavsir} alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-8 col-sm-12">
                        <p className="pt-3 fw-400 fs-17 plus pe-lg-5 font-18">As our Chief Technology Officer, Vaibhav Tripathi leverages his 17 years of experience in the IT industry to lead our technical teams. A BlockChain Architect and AI/ML Enthusiast, Vaibhav is also a Management Consultant and System Owner at SKF AB. His expertise includes over nine years as an SAP consultant, specializing in development, support projects, and SRM technical consulting. Vaibhav's dedication to innovation and his deep technical knowledge drive Datametricks' technological advancements and strategic direction.</p>
                        <p className="fw-600 fs-19 plus mb-0 pt-3">Vaibhav Tripathi</p>
                        <p className="fw-400 fs-16 plus" style={{ color: '#6F6F6F' }}>Founding Member</p>
                        <button type="button" className="btn btn-sm mt-2" style={{ backgroundColor: '#015095' }}>
                         <a href="https://www.linkedin.com/in/vaibhavtripathi/" style={{textDecoration:'none'}}>   <div className="d-flex gap-3 align-items-center">
                                <img src={linkedicon} alt="" />
                                <p className="plus fs-16 text-white mb-0">Follow on Linkedin</p>
                            </div></a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}


<section>
    <div className="container abtmargintop" style={{marginTop:'15%'}}>
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0">
                <img src={dmimg6} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 ">
                <p className="fw-600 plus fs-25 font-23" style={{ color: '#00539A', }}>OUR MISSION</p>
                <p className="fs-17 plus font-18" style={{ color: '#79869F', lineHeight: '36px' }}>Our mission is to empower businesses by providing cutting-edge digital marketing and website development services that foster growth and innovation. We strive to create digital experiences that are not only impactful but also sustainable in the ever-evolving digital world.</p>
            </div>
        </div>
    </div>
</section>


<section>
    <div className="container mt-lg-5 mt-10">
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 order-2 order-md-1">
                <p className="fw-600 plus fs-25 font-23 " style={{ color: '#00539A', }}>OUR VISION</p>
                <p className="fs-17 plus font-18" style={{ color: '#79869F', lineHeight: '36px' }}>We envision a future where businesses of all sizes can harness the power of digital technology to achieve their goals. By staying ahead of industry trends and continuously improving our skills, we aim to be at the forefront of digital innovation.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 order-1 order-md-2 mb-4 mb-md-0">
                <img src={dmimg7} alt="" className="img-fluid" />
            </div>
        </div>
    </div>
</section>

   
<section>
    <div className="container mt-5">
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0">
                <img src={dmimg8} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="fw-600 plus fs-25 font-23" style={{ color: '#00539A', }}>OUR VALUES</p>
                <p className="fs-17 plus" style={{ color: '#79869F' }}>
                    <span className="fs-17 plus fw-500 font-18" style={{ color: '#343434' }}>Innovation:</span> We embrace change and continuously seek new ways to improve and deliver value to our clients
                </p>
                <p className="fs-17 plus" style={{ color: '#79869F' }}>
                    <span className="fs-17 plus fw-500 font-18" style={{ color: '#343434' }}>Excellence:</span> We are committed to maintaining the highest standards of quality in everything we do
                </p>
                <p className="fs-17 plus" style={{ color: '#79869F' }}>
                    <span className="fs-17 plus fw-500 font-18" style={{ color: '#343434' }}>Integrity:</span> We believe in building trust through honest and transparent communication
                </p>
                <p className="fs-17 plus" style={{ color: '#79869F' }}>
                    <span className="fs-17 plus fw-500 font-18" style={{ color: '#343434' }}>Collaboration:</span> We work closely with our clients, understanding their needs and goals to deliver tailored solutions
                </p>
            </div>
        </div>
    </div>
</section>

<section>
    <div className="container mt-lg-5 mt-20 abtourhistory">
        <div className="row">
            <div className="col-lg-6 col-md-12">
                <p className="fw-600 plus fs-25 font-23 mt0" style={{color:'#00539A', marginTop:'10%'}}>OUR HISTORY</p>
                <p className="fs-17 plus font-18" style={{color:'#79869F', lineHeight:'36px'}}>
                    Since our inception, we have helped numerous businesses navigate the complexities of the digital world. Our track record of success is built on a foundation of expertise, dedication, and a relentless pursuit of excellence. We take pride in our ability to adapt to the ever-changing digital landscape, ensuring our clients always stay ahead of the curve.
                </p>
            </div>
            <div className="col-lg-6 col-md-12">
                <img src={dmimg9} alt="" className="img-fluid" />
            </div>
        </div>
    </div>
</section>



<section className="mt-lg-5 mt-30">
    <p className="fw-700 fs-32 plus text-center newfont-24">Let's Build the Future Together</p>
    <div className="mt-4">
        <div className="container text-center py-4" style={{backgroundColor:'#F0EFFF', borderRadius:'20px', paddingLeft:'10%', paddingRight:'10%'}}>
            <p className="fs-17 plus font-18" style={{lineHeight:'36px'}}> We believe in the power of collaboration and innovation. Our team is dedicated to transforming your digital presence and driving your business towards success. Whether you're looking to revamp your website, enhance your digital marketing strategies, or leverage the latest technologies, we have the expertise and passion to make it happen.</p>
        </div>
    </div>
</section>

    <section>
        <div className="mt-5 mb-5 " style={{overflow:'hidden'}}>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="text-white p-5" style={{backgroundColor:'#00539A',borderTopRightRadius:'10px',borderBottomRightRadius:'10px'}}>
                        <h2 className="fs-32 fw-700 plus font-23">We're Here to Help </h2>
                        <p className="fs-18 plus pt-2 font-18"> We believe in the power of collaboration and innovation. Our team is dedicated to transforming your digital presence and driving your business towards success. Whether you're looking to revamp your website, enhance your digital marketing strategies, or leverage the latest technologies, we have the expertise and passion to make it happen. </p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="contactblock-1" style={{paddingLeft:'15%'}}>
                    <div>
                          <img src={callicon} alt="" />
                          <p className="fw-700 fs-16 plus mb-0">Phone </p>
                          <p style={{color:'#8C959F'}}>+91 7303946657  </p>
                    </div>
                    <div className="pt-3">
                          <img src={emailicon} alt="" />
                          <p className="fw-700 fs-16 plus mb-0">Email</p>
                          <p style={{color:'#8C959F'}}>contact@datametricks.com   </p>
                    </div>
                    <div className="pt-3">
                          <img src={locationicon} alt="" />
                          <p className="fw-700 fs-16 plus mb-0">Office</p>
                          <p style={{color:'#8C959F',paddingRight:'30%'}}>A40, ITHUM TOWER, Industrial Area, Sector 62, Noida, Uttar Pradesh 201309.   </p>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </section> 
      
    <section>
  <div>
    <Chatbox/>
  </div>
</section>
     
        <section>
            <Footer/>
        </section>
    </div>
    
);
};
export default Aboutus;