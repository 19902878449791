


const DigitalmarketingCard = ()=>{

const digitalimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/digital-img-1.png";
const digitalimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/digital-img-2.png";
const digitalimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/digital-img-3.png";
const digitalimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/digital-img-4.png";
const digitalimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/digital-img-5.png";

    return(

        <section>
                <div>

                     {/* 1st Card  */}

                        <section>
                  
                  <div className=" mt-lg-5 ">
                    <div className="container  ">
                        <div className="row card123 mx-lg-5  pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12 textcenter">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily:  "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Content Marketing</h1>

                            <p className='cutting-subtitle mx-lg-5 my-lg-4 mt-10 font-18 '>Content marketing is at the heart of our digital marketing strategy. We create high-quality, relevant content that attracts and engages your target audience. Blog posts and articles to videos and infographics, our content is designed to inform, entertain, and inspire. By leveraging SEO best practices, we ensure your content ranks high in search engine results, driving organic traffic and establishing your brand as an authority in your industry.  </p>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12">
                            <img src={digitalimg1} alt="" className="img-fluid pt-10"  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

                 {/* 2nd Card  */}

             <section>
                
                <div>
                  <div className="container mt-lg-3 ">
                      <div className="row card123 mx-lg-5  pos-relative">
                      <div className="overlay1"></div>
                          <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12 textcenter">
                            <h1 className="pt-lg-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Email Marketing</h1>
                          <p className='cutting-subtitle mx-lg-5 my-lg-4 mt-10 font-18'>Email marketing remains one of the most effective ways to reach and engage your audience. Our tailored email campaigns are designed to deliver personalized messages that resonate with your subscribers. We use advanced segmentation and automation techniques to ensure the right message reaches the right person at the right time. Newsletters and promotional emails to drip campaigns and transactional emails, we help you build and nurture relationships with your customers, driving conversions and loyalty.  </p>
                          </div>
                          <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12">
                          <img src={digitalimg2} alt="" className="img-fluid d-none d-sm-none d-lg-block d-md-block"  />

                          </div>
                      </div>
                    </div>
                </div>
      
           </section>

            {/* 3rd Card  */}

           <section>
                  
                  <div >
                    <div className="container mt-lg-3 ">
                        <div className="row card123 mx-lg-5  pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12 textcenter">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Pay-Per-Click (PPC) Advertising</h1>

                            <p className='cutting-subtitle mx-lg-5 my-lg-4 mt-10 font-18 '>Maximize your online visibility and attract high-quality traffic with our PPC advertising services. Our team of certified experts creates targeted ad campaigns on platforms like Google Ads and Bing Ads to reach your ideal customers. We focus on keyword research, ad creation, bid management, and continuous optimization to ensure you get the best return on investment. Whether you’re looking to drive sales, generate leads, or increase brand awareness, our PPC strategies deliver measurable results.  </p>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12">
                            <img src={digitalimg5} alt="" className="img-fluid "  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

                 {/* 4th Card  */}

             <section>
                
                <div >
                  <div className="container mt-lg-3 ">
                      <div className="row card123 mx-lg-5  pos-relative">
                      <div className="overlay1"></div>
                          <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12 textcenter">
                            <h1 className="pt-lg-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Social Media Marketing</h1>
                          <p className='cutting-subtitle mx-lg-5 my-lg-4 mt-10 font-18 '>Expand your brand's reach and engage with your audience on a deeper level with our social media marketing services. At DataMetricks Consulting, we create targeted social media strategies that leverage the unique strengths of platforms like Facebook, Instagram, Twitter, LinkedIn, and more. Content creation and scheduling to community management and paid social campaigns, we help you build a strong, interactive online presence that drives engagement and growth. </p>
                          </div>
                          <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12">
                          <img src={digitalimg4} alt="" className="img-fluid d-none d-sm-none d-lg-block d-md-block"  />

                          </div>
                      </div>
                    </div>
                </div>
      
           </section>


                {/* 5th Card  */}

                <section>
                  
                  <div >
                    <div className="container  mt-lg-3">
                        <div className="row card123 mx-lg-5  pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12 textcenter">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Search Engine Optimization (SEO)</h1>

                            <p className='cutting-subtitle mx-lg-5 my-lg-4 mt-10 font-18 '>Boost your online visibility and attract organic traffic with our comprehensive SEO services. We implement advanced SEO techniques, including keyword research, on-page optimization, link building, and technical SEO, to ensure your website ranks high in search engine results. Our goal is to enhance your site’s relevance and authority, driving targeted traffic and improving your search engine rankings. With DataMetricks, you can achieve sustainable growth and stay ahead of the competition.  </p>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12">
                            <img src={digitalimg3} alt="" className="img-fluid "  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

            



                </div>



        </section>




    )
}
export default DigitalmarketingCard;